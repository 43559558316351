import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '',
    component: () => import("@/views/Layout/Main"),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import("@/views/Home"),
      },
      {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import("@/views/About"),
      },
      {
        path: '/careers',
        name: 'Careers',
        component: () => import("@/views/Careers"),
      },
      {
        path: '/careers/:slug',
        name: 'CareersDetail',
        component: () => import("@/views/CareersDetail"),
      },
    ]
  },
  {
    path: '*',
    redirect: '/',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return {
      x: 0,
      y: 0,
    };
  },
})

export default router
