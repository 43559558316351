import Vue from 'vue'
import Vuex from 'vuex'
import i18n from "@/i18n";
import api from "@/lib/api";
import router from "@/router/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('sgara.lang') || 'id',
    careers: [],
    career: null,
  },
  mutations: {
    UPDATE_CONTACT_PRODUCT(state, payload) {
      state.contact.product = payload
    },
    UPDATE_SETTING(state, payload = []) {
      for (let k in payload) {
        state[k] = payload[k]
        if (Array.isArray(state[k]) || typeof state[k] === 'object')
          localStorage.setItem('sgara.' + k, JSON.stringify(state[k]))
        else
          localStorage.setItem('sgara.' + k, state[k])
      }
    },
    UPDATE_CAREERS(state, payload = []) {
      state.careers = payload
    },
    UPDATE_CAREER(state, payload = null) {
      state.career = payload
    },
  },
  actions: {
    updateLocale({commit}, lang) {
      i18n.locale = lang
      commit('UPDATE_SETTING', {
        lang
      })
    },

    addMessage(context, {name, email, phone, product, company, subject = '', message}) {
      return new Promise((resolve, reject) => {
        api.post('/message', {
          name, email, phone, product, company, subject, message
        }, false).then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    getCareers({commit}) {
      return new Promise((resolve, reject) => {
        api.get('/career').then(res => {
          if (!res.error) {
            commit('UPDATE_CAREERS', res.data)
            return resolve(res)
          }
          reject(res)
        }).catch(err => {
          reject(err)
        })
      })
    },

    getCareerBySlug({commit}, slug) {
      return new Promise((resolve, reject) => {
        api.get('/career', {
          slug,
        }).then(res => {
          if (!res.error) {
            commit('UPDATE_CAREER', res.data)
            return resolve(res)
          }
          router.push('/')
          reject(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
  },
})
