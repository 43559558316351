import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'

import Buefy from 'buefy'
import '@/assets/style.scss'
import i18n from './i18n'
Vue.use(Buefy)

// import VuePlayerPlugin from 'vue-youtube-iframe-api'
// Vue.use(VuePlayerPlugin)

// Vee Validate

import {ValidationProvider, ValidationObserver} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import {extend} from 'vee-validate';
import {required, email} from 'vee-validate/dist/rules';

let locale = i18n.locale

import en from 'vee-validate/dist/locale/en.json';
import id from 'vee-validate/dist/locale/id.json';

extend('required', {
  ...required,
  message: locale === 'en' ? en.messages['required'] : id.messages['required'],
});
extend('email', {
  ...email,
  message: locale === 'en' ? en.messages['email'] : id.messages['email'],
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')